export const checkEmail = async (email?: string) => {
  const data = await fetch(
    `/check-email`,
    {
      method: "POST",
      body: JSON.stringify({
        email
      }),
    }
  )
  .then(response => response)
  .then(data => data)
  .catch(err => err)
}